/*
 * @Author: fredwei
 * @Date: 2019-02-18 11:39:44
 * @Last Modified by: fredwei
 * @Last Modified time: 2019-02-18 14:56:55
 */

.main-container{
	min-height: 500px;
}

.col-wrap{
	position: relative;
	width: 1000px;
	margin: 0 auto;
}

// flex布局
.l-flex {
    position: relative;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}

.l-flex-item {
	box-sizing: border-box;
}