.footer {
	position: relative;
	height: 230px;
	background: rgb(36, 35, 35);
	.col-wrap {
		z-index: 2;
		height: 230px;

		.item {
			display: inline-flex;
			color: #fff;
			padding-top: 30px;

			.item-right{
				padding-left: 10px;

				.title{
					font-weight: bold;
					font-size: 13px;
				}
				.content{
					display: block;
					font-weight:400;
					font-size: 12px;
				}
			}
		}
		.item-bottom{
			line-height:1.2em;
			font-size:12px;
			color: white;
			text-align: right;
			padding-top: 30px;
			font-family: Arial,Helvetica,sans-serif;
		}
	}
	
	
}
