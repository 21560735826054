.dialog {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    overflow: hidden;
}

.dialog_mask {
    height: 100%;
    background: #000;
    opacity: .4;
}

.dialog_btn_close {
    position: absolute;
    top: 11px;
    right: 12px;
    z-index: 10;

    width: 26px;
    height: 26px;

    line-height: 26px;
    text-align: center;

    span {
        display: block;
        height: 100%;
        background: url(../../images/common/icon-close.svg) center no-repeat;
        background-size: cover;
        // font-size: 22px;
        // color: #666;
        transition: color .3s, transform .6s;
    }

    &:hover {
        span {
            color: #333;
            transform: rotate(180deg);
        }
    }
}

.dialog_main {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;

    width: 30%;

    background-color: #fff;

    transform: translate(-50%, -50%);
}

.dialog_head {
    position: relative;
    padding: 20px;
    padding-bottom: 10px;

    line-height: 1.2;
    font-size: 20px;
    color: #000;
}

.dialog_body {
    position: relative;
    padding: 20px;

    font-size: 14px;
    line-height: 1.8;
}

.dialog_footer {
    padding: 20px;
    padding-top: 10px;

    text-align: right;

    button {
        height: 34px;
        padding: 8px 20px;
        margin-left: 10px;
    }
}

// 中尺寸
.dialog_md_main {
    width: 480px;
    margin-left: -240px;
}

// 不上下居中，内容可滚动
.dialog_scroll {
    .dialog_main {
        max-height: 90%;
    }

    .dialog_body {
        padding: 0;

        overflow-y: auto;
    }
}

// 弹出提示
.dialog_toast,
.dialog_logout,
.dialog_confirm {
    min-height: 172px;
    margin-top: -86px;

    .tips {
        position: relative;
        padding-top: 15px;

        font-size: 14px;
        line-height: 30px;

        &:before {
            position: absolute;
            top: 10px;
            left: 29px;
            content: ' ';

            width: 40px;
            height: 40px;
            background: center no-repeat;
        }

        a {
            color: $color-active;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .success {
        padding-left: 90px;
        
        &:before {
            background-image: url(../../images/icon_success_40x40_01.png);
        }
    }

    .error,
    .warning {
        padding-left: 90px;

        &:before {
            background-image: url(../../images/icon_warning_40x40_01.png);
        }
    }
}

.dialog_confirm {
    margin-top: -120px;

    .text {
        padding: 0;
        text-align: center;
    }

    .dialog_body {
        padding: 40px;
    }
}

// 图片预览
.dialog_imgPreview {
    width: auto;
    background-color: transparent;

    .dialog_btn_close {
        opacity: 0;
        top: -35px;
        right: 2px;

        transition: opacity .3s;

        span {
            color: #fff;
            font-size: 24px;
        }
    }

    .dialog_head,
    .dialog_body {
        padding: 0;
    }

    .dialog_body {
        img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 6px;

            opacity: 0;
            overflow: hidden;

            transition: width .6s ease-in, height .6s ease-in, opacity .3s ease-in;
        }
    }

    &.loaded {
        .dialog_btn_close {
            opacity: 1;
        }
    }
}
