/*
 * @Author: fredwei
 * @Date: 2019-02-18 11:41:22
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-11-14 16:03:17
 */

// 主色
$color-active: #0AD4EE;

// 辅助色
$color-success: #67C23A;
$color-warning: #E6A23C;
$color-danger: #F56C6C;
$color-info: #909399;

// 中性色
// 主要文字
$color-text-primary: #000;
// 常规文字
$color-text-regular: #000;
// 次要文字
$color-text-secondary: #909399;
// 占位文字
$color-text-placeholder: #c0c4cc;

// 边框
// 一级边框
$color-border-base: #F0F2F5;
// 二级边框
$color-border-light: #e4e7ed;
// 三级边框
$color-border-lighter: #ebeef5;
// 四级边框
$color-border-extra-light: #f2f6fc;
