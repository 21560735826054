.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 62px;
    margin-top: 50px;

    .logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .nav {
        text-align: right;        

        a {
            display: block;
            padding: 21px 42px 20px 42px;
            line-height: 1;
            font-size: 14px;
            font-weight: 600;
            color: rgb(60,178,178);
            font-family:PingFang SC;
            
            &:hover {
                color: rgb(96,96,96);
            }
        }

        li {
            position: relative;
            display: inline-block;

            &::before {
                position: absolute;
                bottom: 0px;
                left: 50%;
                content: ' ';

                width: 0;
                height: 2px;

                transform: translate(-50%, 0);
                transition: width .3s;
            }

            &:hover {
                &::before {
                    width: 44px;
                }
            }
        }

        .black{
            color: rgb(96,96,96);
        }

        .green{
            color: rgb(60,178,178);
        }
    }
}