html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input,
select,
textarea,
button {
    border: none;
    font-size: 100%;
    margin: 0;
    padding: 0;
}

table {
    table-layout: fixed;
    width: 100%;
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    min-width: 1220px;
    background: #fffeff;

    line-height: 1.6;
    color: $color-text-regular;
    font-size: 12px;
    font-family: Arial, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
    outline: none;
    -webkit-text-size-adjust: 100%;

    &.zh {
        font-family: "Microsoft YaHei", Arial, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: $color-text-regular;
}

section,
article,
aside,
header,
footer,
nav,
dialog,
figure {
    display: block;
}

figure {
    margin: 0;
}

s {
    display: inline-block;
    vertical-align: middle;
}

ol,
ul,
li {
    list-style-type: none;
}

a {
    color: $color-text-regular;
    text-decoration: none;
    outline: none;

    transition: color .3s, border .3s, background .3s;
}

a:hover {
    color: $color-active;
    text-decoration: none;
}

em {
    font-style: normal;
}

/* public */
.clearfix:after,
.clearfix:before {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.none {
    display: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 10px; /*对垂直流动条有效*/
    height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: #f1f1f1;
    // border-radius: 3px;
}


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
    border-radius: 5px;
    // box-shadow: inset 0 0 6px rgba(0,0,0,.5);
    background-color: rgba(0,0,0,.3);
}


/* noscript */
.noscript {
    background: #ffff90;
    border-bottom: 1px solid $color-border-base;
    color: $color-text-regular;
    text-align: center;
}

.noscript .noscript-inner {
    margin: 0 auto;
    width: 1200px;
}

// 默认语言为中文
[data-i18n-en] {
    display: none;
}

.en {
    [data-i18n-en] {
        display: block;
    }

    [data-i18n-zh] {
        display: none;
    }
}

.zh {
    [data-i18n-en] {
        display: none;
    }

    [data-i18n-zh] {
        display: block;
    }
}